import { InformationCircleIcon } from '@heroicons/react/outline'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_LOST_INFO_DELAY,
  MAX_CHALLENGES,
  MAX_WORD_LENGTH,
  REVEAL_TIME_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  GAME_COPIED_MESSAGE,
  GAME_TITLE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import axiosInstance from './lib/axios'
import { digestSecret } from './lib/digestSecret'
import {
  getStoredIsHighContrastMode,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  findFirstUnusedReveal,
  isWinningWord,
  isWordInWordList,
  unicodeLength,
} from './lib/words'

function App() {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const userId = params.get('user_id')
  const gameId = params.get('game_id')
  const access_token = params.get('signature')!
  // useEffect(() => {
  //   alert('token stored');
  //   localStorage.removeItem('signature')
  //   localStorage.setItem('signature', access_token);
  // }, [access_token])

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )

  const [winingWord, setWiningWord] = useState()
  const [tournamentId, setTournament] = useState()

  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    return []
    // const loaded = loadGameStateFromLocalStorage()
    // if (loaded?.solution !== solution) {
    //   return []
    // }
    // const gameWasWon = loaded.guesses.includes(solution)
    // if (gameWasWon) {
    //   setIsGameWon(true)
    // }
    // if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
    //   setIsGameLost(true)
    //   showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
    //     persist: true,
    //   })
    // }
    // return loaded.guesses
  })

  const [keyboard, setKeyboard] = useState()

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  useEffect(() => {
    if (gameId) {
      const data = {
        game_id: gameId,
        time: new Date().toISOString(),
      }
      const digest = digestSecret(data)
      axiosInstance
        .post('game', data, {
          headers: {
            'x-signature': digest,
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          console.log('data', res.data)
          if (res.data.success === false) {
            alert(res.data.message)
            // @ts-ignore
            window.location.replace('http://wordle-backend.test/')
          }
          const data: any[] = []
          const statusContainer: any = []
          res.data.game.guesses.forEach((d: any) => {
            data.push(d.guess)
            const tempStatus: any[] = []
            d.status.forEach((dStatus: any) => {
              tempStatus.push(dStatus.status.toLowerCase())
            })
            statusContainer.push({
              word: d.guess,
              wordStatus: tempStatus,
            })
          })
          localStorage.setItem('wordStatus', JSON.stringify(statusContainer))
          setGuesses(data)
          setKeyboard(res.data.keyboard)
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
          alert(err.message)
          window.location.replace('http://wordle-backend.test/')
        })
    }
  }, [access_token, gameId])

  // useEffect(() => {
  //   if (!loadGameStateFromLocalStorage()) {
  //     setTimeout(() => {
  //       setIsInfoModalOpen(true)
  //     }, WELCOME_INFO_MODAL_MS)
  //   }
  // }, [])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  // useEffect(() => {
  //   saveGameStateToLocalStorage({ guesses, solution })
  // }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (currentGuess.length === 5) {
      const data = {
        user_id: userId,
        game_id: gameId,
        time: new Date().toISOString(),
        guess: currentGuess,
      }
      const digest = digestSecret(data)
      axiosInstance
        .post('guess', data, {
          headers: {
            'x-signature': digest,
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          const data: any[] = []
          const statusContainer: any = []
          res.data.game.guesses.forEach((d: any) => {
            data.push(d.guess)
            const tempStatus: any[] = []
            d.status.forEach((dStatus: any) => {
              tempStatus.push(dStatus.status.toLowerCase())
            })
            statusContainer.push({
              word: d.guess,
              wordStatus: tempStatus,
            })
          })
          localStorage.setItem('wordStatus', JSON.stringify(statusContainer))
          setGuesses(data)
          setKeyboard(res.data.keyboard)
          setTournament(res.data.game.tournament.id)
          setTimeout(() => {
            if (res.data.is_winning === 1) {
              window.location.replace(
                `https://wordle.nagorik.tech/tournament/${res.data.game.tournament.id}`
              )
            }
          }, 3000)
          setWiningWord(res.data.winning_word)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }
      // if (guesses.length === MAX_CHALLENGES - 1) {
      //   setStats(addStatsForCompletedGame(stats, guesses.length + 1))
      //   setIsGameLost(true)
      //   showErrorAlert(CORRECT_WORD_MESSAGE(winingWord || ''), {
      //     persist: true,
      //     delayMs: REVEAL_TIME_MS * MAX_WORD_LENGTH + 1,
      //   })
      // }
    }
  }

  if (winingWord) {
    showErrorAlert(CORRECT_WORD_MESSAGE(winingWord || ''), {
      persist: true,
    })
    setTimeout(() => {
      window.location.replace(
        `https://wordle.nagorik.tech/tournament/${tournamentId}`
      )
    }, 3000)
  }

  console.log('wining_word', winingWord)

  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-20">
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {GAME_TITLE}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        {/*
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        /> */}
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      {keyboard && (
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={guesses}
          keyboard={keyboard}
          isRevealing={isRevealing}
        />
      )}

      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />

      <AlertContainer />
    </div>
  )
}

export default App
